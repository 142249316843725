
@font-face {
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(/layout/fonts/va9C4kDNxMZdWfMOD5VvkrjJYTLVdlTO.woff2) format('woff2'),
    url(/layout/fonts/va9C4kDNxMZdWfMOD5VvkojN.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/layout/fonts/va9E4kDNxMZdWfMOD5Vvl4jLazX3dA.woff2) format('woff2'),
    url(/layout/fonts/va9E4kDNxMZdWfMOD5Vfkw.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/layout/fonts/va9B4kDNxMZdWfMOD5VnLK3eRhf6Xl7Glw.woff2) format('woff2'),
    url(/layout/fonts/va9B4kDNxMZdWfMOD5VnLK3uQg.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
